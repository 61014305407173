import {  Routes, Route, NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AnimatePresence, motion } from "framer-motion";

import Firstpage from "./Components/Firstpage";
import About from "./Components/About";
import Events from "./Components/Events";
import Gallery from "./Components/Gallery";
import Ministries from "./Components/Ministries";
import logo from "./Components/Media/Logo_1.png";

function PageWrapper({ children }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

function App() {
  const [open, setOpen] = useState(false);
  const location = useLocation(); // Now correctly inside <Router>

  return (
    <div className="relative">
      <div className="bg-transparent absolute w-full z-20">
        <div className="w-full mx-auto left-5 right-2 h-28 top-0 text-black flex items-center justify-between px-4">
          <img src={logo} alt="logo" className="h-16" />

          <nav className="max-lg:hidden flex gap-6 w-1/4">
            {["/", "/About", "/Events", "/Gallery"].map((path, index) => (
              <NavLink
                key={index}
                to={path}
                className={({ isActive }) =>
                  `text-xl font-medium ${isActive ? "text-yellow-600 underline" : ""}`
                }
              >
                {path === "/" ? "Home" : path.replace("/", "")}
              </NavLink>
            ))}
          </nav>

          <div className="hidden max-lg:flex">
            <MenuIcon onClick={() => setOpen(true)} className="text-3xl" />
          </div>

          <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
            <div className="w-64 p-4 bg-transparent text-black">
              <List>
                {["/", "/About", "/Events", "/Gallery"].map((path, index) => (
                  <ListItem key={index} onClick={() => setOpen(false)} component={NavLink} to={path}>
                    <ListItemText primary={path === "/" ? "Home" : path.replace("/", "")} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
        </div>
      </div>

      {/* Page Transitions */}
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<PageWrapper><Firstpage /></PageWrapper>} />
          <Route path="/About" element={<PageWrapper><About /></PageWrapper>} />
          <Route path="/ministries" element={<PageWrapper><Ministries /></PageWrapper>} />
          <Route path="/Events" element={<PageWrapper><Events /></PageWrapper>} />
          <Route path="/Gallery" element={<PageWrapper><Gallery /></PageWrapper>} />
        </Routes>
      </AnimatePresence>

      {/* Footer Section */}
      <div className="footer">
        <iframe
          title="Map to church"
          width="100%"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=redeemed%20gospel%20church%20buruburu&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>

      {/* Contact Section */}
      <div className="contacts mx-auto max-w-5xl px-6 py-10 space-y-6">
        <h4 className="text-xl font-medium text-[#926F34] text-center md:text-left">
          Get in touch with us.
        </h4>
        <div className="space-y-2">
          <h6 className="text-lg font-medium text-gray-700">We'd love to hear from you</h6>
          <h5 className="text-lg font-semibold text-[#926F34]">
            Redeemed Gospel Church Buruburu (Blessed Hope Centre)
          </h5>
          <h6 className="text-base text-gray-600">P.O.BOX 32167 – 00600 Nairobi, Kenya</h6>
          <h6 className="text-base text-gray-600">Rabai Road, near Buruburu Girls High School</h6>
        </div>
        <div className="space-y-2">
          <h6 className="text-base text-gray-700">
            <span className="font-medium text-black">Tel:</span> 020-2110772
          </h6>
          <h6 className="text-base text-gray-700">
            <span className="font-medium text-black">Mobile:</span> (+254) 722 385 747 / (+254) 721 449 353
          </h6>
          <a href="mailto:rgcburuburu@gmail.com" className="text-base text-yellow-600 hover:underline">
            E-mail: rgcburuburu@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
