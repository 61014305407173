import React from "react";
import heading from "./Media/heading.jpeg";
import "./Events.css";

function Events() {
  return (
    <div>
    <div
        className="relative"
        style={{
          height: "98vh",
        }}
      >
          <div className="absolute inset-0 bg-black opacity-50"></div> 

        <img
          src={heading}
          alt="header"
          className=" object-cover h-full w-full blur-[0.5px]"
        />
        <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-l-2 border-[#926F34]  h-1/2  flex justify-center align-middle flex-col text-center max-lg:left-8 max-lg:-translate-x-0 max-lg:-translate-y-0 max-lg:top-1/3">
          <h3 className="text-white text-2xl">Where everybody is somebody</h3>
          <h2 className="text-white text-6xl m-4 font-sans max-lg:text-4xl">And Jesus is the Lord</h2>
         
        </div>
      </div>
      <div className="events max-lg:w-11/12 max-lg:mx-auto">
        <h2>Events</h2>
        <div className="eventcard">
          <h3>Faith For Breakthrough</h3>
          <h4>Lunch Hour Service</h4>
          <h6>
            Come and recieve from the Lord during the powerfull lunch hour
            services. The ministers during the services will be Rev. Felix Kavoi
            and Pastor Anne Kavoi. The services will go on throughout the week
            of 29th August till 2nd September 2022 from 12:30pm to 2:00pm at
            Redeemed Gospel Church -Buruburu.
          </h6>
        </div>
        <div className="eventcard">
          <h3>Annual international faith summit</h3>
          <h4>Every August</h4>
          <h6>
            Ministers from around the world are invited to preach and minister
            the word of God in this week long event. People from around the
            world are welcome and food and accomodation is offered for the
            duration of the event.
          </h6>
        </div>
        <div className="eventcard">
          <h3>Annual free medical camp</h3>
          <h4>Immediately after easter.</h4>
          <h6>
            Dentists opticians and medical doctors come to church to treat the
            sick. Medicine required is also offered free of charge. It is open
            to all even if you are not a member of the church.
          </h6>
        </div>
        <div className="service">
          <h2>Order of services</h2>
          <div className="details">
            <h3>Main service times</h3>
            <ol>
              <li>Sundays—6:30am-8:30am, 8:30am-11.00am & 11:15am-1.30pm</li>
              <li>Wednesdays—6:30pm-8.30pm (Bible Study)</li>
              <li>Lunch Hour-12pm-2pm Monday-Friday </li>
            </ol>
          </div>
          <div className="details">
            <h3>Prayer Services</h3>
            <ol>
              <li>Morning Glory-Monday–Saturday—5am-6am</li>
              <li>Prayer Day-Monday 5:30pm-8.00pm</li>
              <li>Lunch Hour-12pm-2pm Monday-Friday </li>
            </ol>
          </div>
          <div className="details">
            <h3>Recources and Media</h3>
            <h6>
              Get access to a variety of materials to enrich and grow your faith
              in God. Get Christian books, sermons, music, videos and plays at
              affordable prices.
            </h6>
            <h6>
              Missed a message? Want to hear it again? Find weekly sermons from
              our Church here.
            </h6>
          </div>
        </div>
      </div>{" "}
   
    </div>
  );
}

export default Events;
