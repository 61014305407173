import React from "react";
import heading from "./Media/praise.jpeg";
import tv from "./Media/ebn-tv-official-logo 1.png";
import ytube from "./Media/ytube 1.png";
import blog from "./Media/sanctuary dedication.jpg";
import ExampleDoc from "./Media/RGC BURUBURU CHURCH 877.pdf";
import faith from "./Media/faith.jpg";
import medical from "./Media/IMG_0356.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import "./Firstpage.css";

function Firstpage() {
  React.useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <div
        className="relative "
        style={{
          height: "98vh",
        }}
      >
          <div className="absolute inset-0 bg-black opacity-20"></div> 

        <img
          src={heading}
          alt="header"
          className=" object-cover h-full w-full "
        />
        <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-l-2 border-[#926F34]  h-1/2  flex justify-center align-middle flex-col text-center max-lg:left-8 max-lg:top-1/3 max-lg:-translate-x-0 max-lg:-translate-y-0">
          <h3 className="text-white text-2xl">Welcome to</h3>
          <h2 className="text-white text-6xl m-4 font-sans max-lg:text-4xl">
            Blessed Hope Centre
          </h2>
          <h3 className="text-white text-4xl max-lg:text-2xl">BuruBuru</h3>
        </div>
      </div>

      <div className="content">
        <div
          className="Welcome"
          style={{
            color: "#000",
          }}
        >
          <h2
            style={{
              borderBottom: "none",
              textAlign: "center",
              fontWeight: "200",
              color: "#000",
            }}
          >
            "Surely God is in this place"
          </h2>
          <h3
            style={{
              color: "#000",
              padding: "0.5rem",
            }}
          >
            Genesis 28:16
          </h3>
        </div>
        <div className="Welcome">
          <h2>Welcome and feel at Jesus' feet.</h2>
          <h5>
            At Blessed Hope Centre, we embrace the core ingredients of the
            Christianity Faith and present them in a way that is real, bold,
            smart and fun. Our driving desire is to encourage everyone, share
            the biblical message that Jesus Christ is the Saviour, author of our
            Salvation, that He loves every man unconditionally, and that He
            desires a personal, one-on-one relationship with all men.{" "}
          </h5>
          <button onClick={() => navigate("/About")}>Learn More</button>
        </div>
        <div className="events max-lg:w-11/12 max-lg:mx-auto">
          <h2 className="">Recent Sermons</h2>
          <div className="cards">
            <div className="eventscard">
              <iframe
                width="450"
                height="450"
                src="https://www.youtube.com/embed/MF4JmzQwSqs?si=dpq9Xa16f-J5pLPx"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
              ></iframe>
              <div className="eventsinfo">
                <h4 style={{ fontWeight: "400" }}>
                  New Month Dedication Service
                </h4>
              </div>
            </div>
            <div className="eventscard2">
              <iframe
                width="450"
                height="450"
                src="https://www.youtube.com/embed/aKDo2ZnA5g0?si=bBowu7bZ6RQhchgP"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div className="eventsinfo">
                <h4 style={{ fontWeight: "300" }}>
                  2nd service Live from R.G.C-Buruburu
                </h4>
              </div>
            </div>
          </div>

          <a
            className="maglink"
            href="https://www.youtube.com/c/EcclesiaBroadcastingNetwork"
          >
            See All Videos
          </a>
        </div>

        <div className="events max-lg:w-11/12 max-lg:mx-auto">
          <h2>Events</h2>
          <div className="cards">
            <div className="eventscard mb-4">
              <img
                src={faith}
                alt="..."
                data-aos="fade-in"
                style={{ height: "18rem",objectFit:"cover" }}
              />
              <div className="eventsinfo" onClick={() => navigate("/Events")}>
                <h2>International Faith Summit</h2>
                <h4>14th - 18th August 2023</h4>
                <h6>
                  Redeemed Gospel Church is hosting the annual International
                  faith summit from the 14th t0 the 18th of August 2023. Come
                  and join believers from all over the world with guest speakers
                  who will lead in delivering the theme of the year: Standing
                  For The Faith Amidst Challenges. All are welcome!
                </h6>
              </div>
            </div>
            <div className="eventscard2" onClick={() => navigate("/Events")}>
              <img
                src={medical}
                alt="..."
                data-aos="fade-in"
                style={{ height: "18rem",objectFit:"cover" }}
              />
              <div className="eventsinfo" onClick={() => navigate("/Events")}>
                <h2>Free Medical camp 2025</h2>
                <h4>Coming soon</h4>
                <h6>
                  Redeemed Gospel Church is hosting the annual free medical camp
                  . Come and get medical services suc as general
                  consultation, Dental Extraction, Eye services, cancer
                  screening and councelling. All are welcome!
                </h6>
              </div>
            </div>
          </div>
          <button onClick={() => navigate("/Events")}>See All Events</button>
        </div>

        <div className=" max-w-5xl mx-auto px-6 py-10 space-y-6 text-center">
          <h2 className="text-3xl font-semibold text-[#926F34]">
            Watch Us Live
          </h2>

          <div className=" flex flex-col md:flex-row justify-center items-center gap-8">
            <div className="h-80 bg-white border border-yellow-600 rounded-xl p-6 flex flex-col items-center">
              <a
                href="https://ebntv.co.ke"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={tv}
                  alt="EBN TV"
                  className="w-32 h-auto hover:opacity-80 transition"
                />
              </a>
              <div className="tvInfo mt-4 space-y-3">
                {["Startimes", "Gotv", "Pang"].map((provider, index) => (
                  <div
                    key={index}
                    className="channel flex items-center space-x-2"
                  >
                    <h6 className="text-lg font-medium text-gray-700">
                      {provider}
                    </h6>
                    <div className="h-4 w-auto" />
                    <h6 className="text-lg font-semibold text-[#926F34]">
                      Channel 484
                    </h6>
                  </div>
                ))}
              </div>
            </div>

            <div className="h-80 bg-white border border-yellow-600 rounded-xl p-6 flex flex-col items-center">
              <a
                href="https://www.youtube.com/@RedeemedGospelChurch-Buruburu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ytube}
                  alt="YouTube"
                  className="w-32 h-auto hover:opacity-80 transition"
                />
              </a>
              <div className=" mt-4 text-gray-700 text-lg space-y-2">
                <h6>Click here to watch previous sermons</h6>
                <h6>Or</h6>
                <h6>
                  Visit
                  <a
                    href="https://www.youtube.com/@RedeemedGospelChurch-Buruburu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline ml-1"
                  >
                    Redeemed Gospel Church on YouTube
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="services max-w-4xl mx-auto px-6 py-10 text-center space-y-1 max-lg:px-2">
          <h2 className="text-3xl font-semibold text-[#926F34] pb-2">
            Order of Services
          </h2>

          <div className="flex justify-evenly  pt-10 md:flex-col max-lg:flex-col">
            <div className="h-72 w-72 bg-white shadow-md mb-4 rounded-xl p-6 border-t-4 border-[#926F34] ">
              <h3 className="text-2xl font-medium text-gray-800">
                Sunday Services
              </h3>
              <div className="mt-4 space-y-2 text-gray-700 text-lg">
                <h6>
                  <span className="font-semibold text-black">
                    First Service:
                  </span>{" "}
                  7:30 to 10:00 AM
                </h6>
                <h6>
                  <span className="font-semibold text-black">
                    Second Service:
                  </span>{" "}
                  10:30 to 12:45 PM
                </h6>
              </div>
            </div>

            {/* Mid-Week Services */}
            <div className="h-72 w-72 bg-white shadow-md rounded-xl p-6 border-t-4 border-[#926F34]">
              <h3 className="text-2xl font-medium text-gray-800">
                Mid-Week Services
              </h3>
              <div className="mt-4 space-y-2 text-gray-700 text-lg">
                <h6>
                  <span className="font-semibold text-black">Monday:</span>{" "}
                  Prayers at 6:00 PM
                </h6>
                <h6>
                  <span className="font-semibold text-black">Tuesday:</span> HBC
                  Fellowship at 6:00 PM
                </h6>
                <h6>
                  <span className="font-semibold text-black">Wednesday:</span>{" "}
                  Service at 6:00 PM
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="Blog max-lg:w-11/12 max-lg:mx-auto">
          <h2 className="text-3xl font-semibold text-[#926F34] pb-2">Blogs</h2>
          <div className="flex justify-between max-lg:flex-col">
            <img src={blog} alt="..." />
            <div className="blogText max-lg:text-2xl text-4xl ml-5">
              <h3 className="">Sanctuary Dedication Magazine</h3>
              <h6>
                The church was dedicated and officially opened. Please click on
                the button below to download and read the story of the
                sanctuary's construction, the Minister's faith, all the
                congatulatory messages as well as images of the progress of the
                sanctuary's construction.
              </h6>
            </div>
          </div>
          <a
            href={ExampleDoc}
            download="Sanctuary dedication magazine"
            className="maglink"
          >
            Download Magazine
          </a>
        </div>
      </div>
    </div>
  );
}

export default Firstpage;
